import { Alert, Box, Button, Container, Stack, TextField } from "@mui/material";
import axios from "axios";
import React from "react";
import './Login.css';
import { useCookies } from 'react-cookie';
import { Mode } from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Helper from "../helper";
import DiamondIcon from '@mui/icons-material/Diamond';
import Loader from "../Loader/Loader";

const Dashboard = () => {
    const navigate = useNavigate();
    const [model, setModel] = React.useState({ email: '', password: '' });
    const [cookies, setCookie] = useCookies(['email', 'access_token'])
    const [error, setError] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    if (cookies.access_token) {
        navigate("/");
    }

    React.useEffect(() => {
        if (cookies.email)
            setModel({ ...model, email: cookies.email });
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setError(false);
        setCookie('email', model.email, { path: "/", expires: moment().add(365, 'd').toDate() });

        setLoader(true);
        axios.post('/api/user/login', model)
            .then(function (response) {
                setCookie('access_token', response.data, { path: "/", expires: moment().add(7, 'd').toDate() })
                navigate("/");
            })
            .catch(function (error) {
                setError(true);
                setModel({ ...model, password: '' });
                Helper.httpErrorhandler(error);
            }).finally(function () {
                setLoader(false);
            });
    };
    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setModel({ ...model, [event.target.name]: event.target.value });
    }

    return (
        <Container className="login">
            <Loader isVisible={loader} />
            <Box
                className="mt-30 login-box"
                component="form"
                sx={{ '& .MuiTextField-root': { pb: 1.5, width: '100%' }, }}
                autoComplete="off"
                onSubmit={handleSubmit} >
                <div className="text-center">
                    <DiamondIcon className="login-logo" />
                </div>
                <h2 className="text-center">
                    Dalali App
                </h2>
                {error ? (<Alert severity="error" className="mb-30">Invalid email/password.</Alert>) : ("")}
                <TextField
                    size="small"
                    required
                    label="Email"
                    name="email"
                    onChange={onValueChange}
                    title="Please enter valid email address"
                    inputProps={{ pattern: '[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}' }}
                    value={model.email} />
                <TextField
                    size="small"
                    type="password"
                    required
                    label="Password"
                    name="password"
                    onChange={onValueChange}
                    value={model.password} />
                <Stack spacing={2} direction="row" className="mb-30">
                    <Button variant="contained" type="submit">
                        Login
                    </Button>
                </Stack>

                <div className="text-small text-center">
                    ©2022 Developed by Anil Vasani
                </div>

            </Box>
        </Container>
    );

}

export default Dashboard;