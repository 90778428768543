import { Backdrop, CircularProgress } from '@mui/material';

type Props = {
    isVisible: boolean;
};

const Loader: React.FC<Props> = ({ isVisible }) => {

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isVisible}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );

}

export default Loader