import { Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import * as React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Moment from 'react-moment';
import Helper from '../helper';
import NavigationBar from '../NavigationBar/NavigationBar';
import Loader from '../Loader/Loader';
import SaveIcon from '@mui/icons-material/Save';

const AddDalali = () => {
    const navigate = useNavigate();
    const dateFormat = 'DD-MM-yy';

    const [sell, setSell] = React.useState(Helper.getSellObject());
    const [buyers, setBuyers] = React.useState([]);
    const [sellers, setSellers] = React.useState([]);
    const [sellId, setSellId] = React.useState(0);
    const [loader, setLoader] = React.useState(false);

    React.useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            let sellId = params.get('id');

            setLoader(true);
            axios.get('/api/sell/get?sellId=' + sellId)
                .then(function (response) {
                    //console.log(response);
                    setSellId(response.data.sellId);
                    setSell(response.data);
                })
                .catch(function (error) {
                    Helper.httpErrorhandler(error);
                }).finally(function () {
                    setLoader(false);
                });
        }

        axios.get('/api/sell/getAllBuyerNames')
            .then(function (response) {
                setBuyers(response.data);
            })
            .catch(function (error) {
                Helper.httpErrorhandler(error);
            });

        axios.get('/api/sell/getAllSellerNames')
            .then(function (response) {
                setSellers(response.data);
            })
            .catch(function (error) {
                Helper.httpErrorhandler(error);
            });

    }, []);

    const onSelectValueChange = (event: SelectChangeEvent) => {
        setSell({ ...sell, commissionType: Number(event.target.value) });
    };

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.name) {
            case 'days':
                setSell({
                    ...sell, paymentDueDate: moment(sell.dateForSell).add(event.target.value, 'd').toISOString(),
                    days: event.target.value
                });
                break;
            case 'price':
                setSell({
                    ...sell, price: event.target.value,
                    commission: (totalSell(null, event.target.value, null) * Number(sell.commissionPercent)) / 100
                });
                break;
            case 'dollarPrice':
                setSell({
                    ...sell, dollarPrice: event.target.value,
                    commission: (totalSell(event.target.value, null, null) * Number(sell.commissionPercent)) / 100
                });
                break;
            case 'commissionPercent':
                setSell({
                    ...sell, commissionPercent: event.target.value,
                    commission: (totalSell(null, null, null) * Number(event.target.value)) / 100
                });
                break;
            case 'netLess':
                setSell({
                    ...sell, netLess: event.target.value,
                    commission: (totalSell(null, null, event.target.value) * Number(sell.commissionPercent)) / 100
                });
                break;
            case 'commission':
                const total = totalSell(null, null, null);
                const newCommision = (Number(event.target.value) * 100) / total;

                setSell({
                    ...sell, commission: Number(event.target.value),
                    commissionPercent: newCommision.toString()
                });
                break;
            default:
                if (event.target.type === 'number') {
                    setSell({ ...sell, [event.target.name]: Number(event.target.value) });
                }
                if (event.target.type === 'checkbox') {
                    if (event.target.name == 'isReceived')
                        setSell({ ...sell, isReceived: event.target.checked });
                    else
                        setSell({ ...sell, isDebitNoteReceived: event.target.checked });
                }
                else
                    setSell({ ...sell, [event.target.name]: event.target.value });
                break;
        }
    }

    const onBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.target.value = toTitleCase(event.target.value);
    }

    function toTitleCase(str: any) {
        return str.replace(
            /\w\S*/g,
            function (txt: any) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    const onValueChangeForDateControl = (value: any) => {
        setSell({
            ...sell, paymentDueDate: moment(value).add(sell.days, 'days').toISOString(),
            dateForSell: value
        });
    }

    const onAutoCompleteValueChangeSeller = (event: React.BaseSyntheticEvent, values: any) => {
        setSell({ ...sell, sellerName: values });
    }
    const onAutoCompleteValueChangeBuyer = (event: React.BaseSyntheticEvent, values: any) => {
        setSell({ ...sell, buyerName: values });
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (sellId > 0) {
            // update
            axios.post('/api/sell/update', sell)
                .then(function (response) {
                    navigate('/');
                })
                .catch(function (error) {
                    Helper.httpErrorhandler(error);
                });
        } else {
            axios.post('/api/sell/create', sell)
                .then(function (response) {
                    navigate('/');
                })
                .catch(function (error) {
                    Helper.httpErrorhandler(error);
                });
        }
    };

    const onClickCancel = () => {
        navigate('/');
    }

    const numberFormat = (value: number) =>
        new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0
        }).format(value);

    const totalSell = (dollarPrice: any, price: any, netLess: any) => {
        if (netLess ?? sell.netLess) {
            const total = (Number(dollarPrice ?? sell.dollarPrice) * Number(price ?? sell.price));
            return total - ((total * Number(netLess ?? sell.netLess)) / 100);
        } else
            return (Number(dollarPrice ?? sell.dollarPrice) * Number(price ?? sell.price));
    }
    //const totalDalali = () => {
    //    return (totalSell() * Number(sell.commissionPercent)) / 100;
    //}

    return (
        <div>
            <Loader isVisible={loader} />
            <NavigationBar />
            <Container className="appbar-margin-top">
                <Box
                    component="form"
                    sx={{ '& .MuiTextField-root': { pb: 1.5, width: '100%' }, }}
                    autoComplete="off"
                    onSubmit={handleSubmit} >

                    <div>
                        <h3>
                            New Dalali
                        </h3>
                    </div>
                    <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Date"
                                inputFormat="dd-MM-yyyy"
                                value={sell.dateForSell}
                                onChange={onValueChangeForDateControl}
                                renderInput={(params) => <TextField size="small" name="dateForSell" {...params} />}
                            />
                        </LocalizationProvider>

                        <Autocomplete
                            size="small"
                            freeSolo
                            options={sellers}
                            value={sell.sellerName}
                            onInputChange={onAutoCompleteValueChangeSeller}
                            renderInput={(params) => <TextField {...params} required label="Seller Name" name="sellerName" />}
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    type="number"
                                    required
                                    label="Caret"
                                    name="caret"
                                    onChange={onValueChange}
                                    value={sell.caret}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    type="number"
                                    required
                                    name="cent"
                                    label="Cent"
                                    onChange={onValueChange}
                                    value={sell.cent}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    type="number"
                                    required
                                    name="commissionPercent"
                                    label="Dalali"
                                    value={sell.commissionPercent}
                                    onChange={onValueChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                    }}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]+([\.,][0-9]+)?' }} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>

                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    required
                                    name="price"
                                    label="Price"
                                    value={sell.price}
                                    onChange={onValueChange}
                                    inputProps={{
                                        inputMode: 'numeric', pattern: '[0-9]+([\.,][0-9]+)?'
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">₹</InputAdornment>
                                    }} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    required
                                    name="dollarPrice"
                                    label="Dollar Rate"
                                    value={sell.dollarPrice}
                                    onChange={onValueChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]+([\.,][0-9]+)?' }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    size="small"
                                    name="netLess"
                                    label="Net/Less"
                                    value={sell.netLess}
                                    onChange={onValueChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        startAdornment: <InputAdornment position="start">-</InputAdornment>
                                    }}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]+([\.,][0-9]+)?' }} />
                            </Grid>
                        </Grid>
                        {/*<Grid container spacing={2}>*/}
                        {/*    <Grid item xs={6}>*/}

                        {/*    </Grid>*/}
                        {/*    <Grid item xs={6}>*/}

                        {/*    </Grid>*/}
                        {/*</Grid>*/}



                        <Autocomplete
                            size="small"
                            freeSolo
                            options={buyers}
                            value={sell.buyerName}
                            onInputChange={onAutoCompleteValueChangeBuyer}
                            renderInput={(params) => <TextField {...params} required label="Buyer Name" name="buyerName" />}
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    size="small"
                                    type="number"
                                    required
                                    name="days"
                                    label="Days"
                                    value={sell.days}
                                    onChange={onValueChange}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                            </Grid>
                            <Grid item xs={6}>
                                <div className="textarea-samecolor mt--5 mb-5">
                                    Payment Due Date
                                </div>
                                <Moment date={sell.paymentDueDate} format={dateFormat} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="mb-15">
                            <Grid item xs={6}>
                                <div className="textarea-samecolor mt--5 mb-5">
                                    Total Sell
                                </div>
                                {numberFormat(totalSell(sell.dollarPrice, sell.price, sell.netLess))}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    size="small"
                                    required
                                    name="commission"
                                    label="Dalali"
                                    value={sell.commission.toFixed(0)}
                                    onChange={onValueChange}
                                    inputProps={{
                                        inputMode: 'numeric', pattern: '[0-9]+([\.,][0-9]+)?'
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">₹</InputAdornment>
                                    }} />
                                {/*<div className="textarea-samecolor mt--5 mb-5">*/}
                                {/*    Dalali*/}
                                {/*</div>*/}
                                {/*{numberFormat(totalDalali())}*/}
                            </Grid>
                        </Grid>

                        <TextField
                            size="small"
                            name="viaBroker"
                            label="Notes"
                            multiline
                            rows={2}
                            onChange={onValueChange}
                            value={sell.viaBroker} />

                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-select-small" className="customLabel">Payment Method</InputLabel>
                            <Select
                                labelId="demo-select-small"                                
                                value={sell.commissionType.toString()}
                                name="commissionType"
                                size="small"
                                label="Age"                                
                                onChange={onSelectValueChange}
                            >
                                <MenuItem value={0}>None</MenuItem>
                                <MenuItem value={1}>Cash</MenuItem>
                                <MenuItem value={2}>NEFT</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControlLabel control={<Checkbox checked={sell.isReceived} onChange={onValueChange} color="success" name="isReceived" />} label="Dalali Received" className="mb-15" />
                        <FormControlLabel control={<Checkbox checked={sell.isDebitNoteReceived} onChange={onValueChange} color="success" name="isDebitNoteReceived" />} label="Debit Note Received" className="mb-15" />
                    </div>
                    <Stack spacing={2} direction="row" className="pb-200">
                        <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
                            {sellId > 0 ? 'Update' : 'Save'}
                        </Button>
                        <Button variant="text" onClick={onClickCancel}>Cancel</Button>
                    </Stack>
                </Box>
            </Container>
        </div>
    );
}
export default AddDalali