import { Alert, Button, colors, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import axios from 'axios';
import Moment from 'react-moment';
import './Dashboard.css';
import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import Helper from '../helper';
import NavigationBar from '../NavigationBar/NavigationBar';
import Loader from '../Loader/Loader';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import el from 'date-fns/esm/locale/el/index.js';
import moment from 'moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const Dashboard = () => {
  const dateFormat = 'DD-MM-yy';
  const navigate = useNavigate();
  const [allSells, setAllSells] = React.useState([Helper.getSellObject()]);
  const [allFilteredSells, setAllFilteredSell] = React.useState([Helper.getSellObject()]);
  const [loader, setLoader] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [errorSnackbar, showErrorSnackbar] = React.useState(false);
  const [pendingToDelete, setPendingToDelete] = React.useState(0);
  const [search, setSearchText] = React.useState("");
  const [searchType, setSearchType] = React.useState('s');
  const [dateFilter, setDateFilter] = React.useState('0');
  const dateFilterOptions = [1, 0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12];
  const [dateStart, setDateStart] = React.useState(moment().add(-7, 'days').toISOString());
  const [dateEnd, setDateEnd] = React.useState(moment().toISOString());
  const [showCustomDates, setShowCustomDates] = React.useState(false);

  React.useEffect(() => {
    getSells(dateFilter);
  }, []);

  const numberFormat = (value: number) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0
    }).format(value);

  function getSells(month: any, startDate: any = undefined, endDate: any = undefined) {
    setLoader(true);

    let url = '';
    if (month != undefined) {
      url = `/api/sell/getAll?month=${month}`;
    } else {
      url = `/api/sell/getAll?startDate=${startDate}&endDate=${endDate}`;
    }

    axios.get(url)
      .then(function (response) {
        setAllSells(response.data);
        setAllFilteredSell(response.data);
      })
      .catch(function (error) {
        Helper.httpErrorhandler(error);
      }).finally(function () {
        setLoader(false);
      });
  }
  const onClickAdd = () => {
    navigate('/addDalali');
  };

  const totalDalali = () => {
    let total = 0;
    allFilteredSells.forEach((item) => { total += item.commission; })
    return total;
  }
  const totalSell = () => {
    let total = 0;
    allFilteredSells.forEach((item) => { total += item.sellValue; })
    return total;
  }
  const totalDalaliReceived = () => {
    let total = 0;
    allFilteredSells.forEach((item) => {
      if (item.isReceived)
        total += item.commission;
    })
    return total;
  }
  const totalDalaliPending = () => {
    let total = 0;
    allFilteredSells.forEach((item) => {
      if (!item.isReceived)
        total += item.commission;
    })
    return total;
  }
  const totalDalaliByType = (type: number) => {
    let total = 0;
    allFilteredSells.forEach((item) => {
      if (item.commissionType == type)
        total += item.commission;
    })
    return total;
  }

  const totalPrice = () => {
    let total = 0;
    allFilteredSells.forEach((item) => { total += Number(item.price); })
    return total;
  }
  const avgDollar = () => {
    let total = 0;
    allFilteredSells.forEach((item) => { total += Number(item.dollarPrice); })
    return total / allFilteredSells.length;

  }
  const handleClick = (event: React.MouseEvent<unknown>, sellId: number) => {
    navigate('/addDalali?id=' + sellId.toString());
  }

  const onClickDalaliReceived = (event: any, sellId: number, type: number) => {
    let sellObject: any;
    const newState = allFilteredSells.map(obj => {
      if (obj.sellId === sellId) {
        sellObject = obj;
        switch (type) {
          case 1:
            return { ...obj, isReceived: !obj.isReceived };
          case 2:
            return { ...obj, isDebitNoteReceived: !obj.isDebitNoteReceived };
          case 3:
            return { ...obj, commissionType: Number(event.target.value) };
        }
      }
      return obj;
    });
    setAllSells(newState);

    if (sellObject) {
      switch (type) {
        case 1:
          sellObject.isReceived = !sellObject.isReceived;
          break;
        case 2:
          sellObject.isDebitNoteReceived = !sellObject.isDebitNoteReceived;
          break;
        case 3:
          sellObject.commissionType = Number(event.target.value);
          break;
      }

      axios.post('/api/sell/update', sellObject)
        .then(function (response) {

        })
        .catch(function (error) {
          Helper.httpErrorhandler(error);
        });
    }
  }

  const handleDelete = (event: React.MouseEvent<unknown>, sellId: number) => {
    setPendingToDelete(sellId);
    setDialogOpen(true)
  }
  const deleteDalali = () => {
    setDialogOpen(false);
    axios.post('/api/sell/delete?sellId=' + pendingToDelete)
      .then(function (response) {
        var filteredArray = allFilteredSells.filter(item => item.sellId != pendingToDelete);
        setAllSells(filteredArray);
        setPendingToDelete(0);
      })
      .catch(function (error) {
        showErrorSnackbar(true);
        Helper.httpErrorhandler(error);
      });
  }
  const showNetLess = (netLess: string) => {
    if (Number(netLess) > 0) {
      return '(-' + netLess + '%) ';
    } else
      return '';

  }
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    if (value) {
      var filteredArray;
      if (searchType == 's')
        filteredArray = allSells.filter(item => item.sellerName.toLowerCase().startsWith(value.toLowerCase()));
      else
        filteredArray = allSells.filter(item => item.buyerName.toLowerCase().startsWith(value.toLowerCase()));
      setAllFilteredSell(filteredArray);
    } else
      setAllFilteredSell(allSells);
  }
  const onDateFilterChange = (event: SelectChangeEvent) => {
    if (event.target.value === "custom") {
      setShowCustomDates(true);
      setDateFilter('1');
      setAllSells([]);
      setAllFilteredSell([]);
      return;
    }
    setDateFilter(event.target.value as string);
    getSells(event.target.value as string);
  };
  const onSearchTypeChange = (event: SelectChangeEvent) => {
    setSearchType(event.target.value);
    setAllFilteredSell(allSells);
    setSearchText('');
  };
  const onCustomDateShow = () => {
    setShowCustomDates(false);
    getSells(undefined, dateStart, dateEnd);
  }

  return (
    <div>
      <Loader isVisible={loader} />
      <NavigationBar />
      <Container className="appbar-margin-top">
        <div className="mt-15 mb-15">

          <div className="mb-15">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="monthLabel">Type</InputLabel>
                  <Select
                    size="small"
                    labelId="monthLabel"
                    value={searchType}
                    name="month"
                    label="Month"
                    onChange={onSearchTypeChange} >
                    <MenuItem value="s">S</MenuItem>
                    <MenuItem value="b">B</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  name="netLess"
                  label="Search"
                  value={search}
                  fullWidth
                  onChange={onSearchChange}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="monthLabel">Month</InputLabel>
                  <Select
                    size="small"
                    labelId="monthLabel"
                    value={dateFilter}
                    name="month"
                    label="Month"
                    onChange={onDateFilterChange} >
                    <MenuItem value="custom">
                      Custom
                    </MenuItem>
                    {dateFilterOptions.map((row) => (
                      <MenuItem value={row}>
                        {moment().add(row, 'months').format("MM-YYYY")}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>

          {dateFilter == "1" ?
            (
              <div className="mb-15">
                <strong>From:</strong> <Moment date={dateStart} format={dateFormat} /> <strong> To: </strong> <Moment date={dateEnd} format={dateFormat} />
              </div>
            ) : ""}
          <TableContainer component={Paper} id="list" className="zoom-low">
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" stickyHeader >
              <TableHead className="table-header">
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="right">Seller Name</TableCell>
                  <TableCell align="right">Weight</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">%</TableCell>
                  <TableCell align="right">$</TableCell>
                  {/*<TableCell align="right">Net/Less</TableCell>*/}
                  <TableCell align="right">Buyer Name</TableCell>
                  {/*<TableCell align="right">Days</TableCell>*/}
                  <TableCell align="right">Payment Date</TableCell>
                  <TableCell align="right">Total Sell</TableCell>
                  <TableCell align="right">Dalali</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allFilteredSells.map((row) => (
                  <TableRow
                    className={row.isReceived ? 'received' : ''}
                    key={row.sellId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      <Moment date={row.dateForSell} format="DD-MM-yy" />
                    </TableCell>
                    <TableCell align="right">{row.sellerName}</TableCell>
                    <TableCell align="right">{row.caret}.{row.cent}</TableCell>
                    <TableCell align="right">{numberFormat(Number(row.price))}</TableCell>
                    <TableCell align="right">{Number(row.commissionPercent).toFixed(2)}%</TableCell>
                    <TableCell align="right">{Number(row.dollarPrice).toFixed(2)}</TableCell>
                    {/*<TableCell align="right">-{row.netLess}%</TableCell>*/}
                    <TableCell align="right">{row.buyerName}</TableCell>

                    <TableCell align="right">
                      <Moment date={row.paymentDueDate} format="DD-MM-yy" /> ({row.days})
                    </TableCell>
                    <TableCell align="right">
                      {showNetLess(row.netLess)}
                      {numberFormat(row.sellValue)}
                    </TableCell>
                    <TableCell align="right">{numberFormat(row.commission)}</TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="Received" onClick={(e) => onClickDalaliReceived(e, row.sellId, 1)}>
                        {row.isReceived ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                      </IconButton>
                      <IconButton aria-label="Edit" color="primary" onClick={(e) => handleClick(e, row.sellId)} hidden={row.isReceived}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="Delete" color="error" onClick={(e) => handleDelete(e, row.sellId)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell width="300">
                      <IconButton aria-label="Received" onClick={(e) => onClickDalaliReceived(e, row.sellId, 2)}>
                        {row.isDebitNoteReceived ? <CheckBoxIcon color="secondary" /> : <CheckBoxOutlineBlankIcon />}
                      </IconButton> Debit
                    </TableCell>
                    <TableCell>
                      <RadioGroup row className="customRadioGroup" value={row.commissionType}>
                        <FormControlLabel value="1" control={<Radio onChange={(e) => onClickDalaliReceived(e, row.sellId, 3)} />} label="Cash" />
                        <FormControlLabel value="2" control={<Radio onChange={(e) => onClickDalaliReceived(e, row.sellId, 3)} />} label="NEFT" />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>

                ))}
                <TableRow key="total" className="total-row"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">{numberFormat(totalPrice())}</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">AVG: {avgDollar().toFixed(2)}</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">{numberFormat(totalSell())}</TableCell>
                  <TableCell align="right">{numberFormat(totalDalali())}</TableCell>
                  <TableCell>{((totalDalali() / totalSell()) * 100).toFixed(2)}%</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="mb-30">
          <strong>Cash: </strong> {numberFormat(totalDalaliByType(1))}
          <br />
          <strong>NEFT: </strong> {numberFormat(totalDalaliByType(2))}
          <br />
          <strong>Pending:</strong> {numberFormat(totalDalaliPending())}
          <br />
          <strong>Received:</strong> {numberFormat(totalDalaliReceived())}
        </div>
        <Fab
          onClick={onClickAdd}
          color="secondary"
          sx={{
            position: 'fixed',
            bottom: '15px',
            right: '15px',
          }}
        >
          <AddIcon />
        </Fab>
      </Container>

      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Would you like to delete this dalali?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setDialogOpen(false) }} autoFocus>No</Button>
          <Button onClick={deleteDalali}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={errorSnackbar} autoHideDuration={4000} onClose={() => { showErrorSnackbar(false) }}>
        <Alert severity="error">Oops! Something went wrong.</Alert>
      </Snackbar>

      <Dialog
        open={showCustomDates}
        onClose={() => { setShowCustomDates(false); }}
      >
        <DialogTitle>Select dates</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select start & end date.
          </DialogContentText>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Start"
                  inputFormat="dd-MM-yyyy"
                  value={dateStart}
                  onChange={(value) => { setDateStart(moment(value).toISOString()); }}
                  renderInput={(params) => <TextField size="small" name="dateStart" {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="End"
                  inputFormat="dd-MM-yyyy"
                  value={dateEnd}
                  onChange={(value) => { setDateEnd(moment(value).toISOString()); }}
                  renderInput={(params) => <TextField size="small" name="dateStart" {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={onCustomDateShow} autoFocus>Show</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
export default Dashboard