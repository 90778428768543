import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import Button from '@mui/material/Button';
import NavigationBar from '../NavigationBar/NavigationBar';
import { Container } from '@mui/material';
import ScreenRouter from '../ScreenRouter/ScreenRouter';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";


function App() {
    const [cookies, setCookie] = useCookies(['email', 'access_token'])

    if ((cookies.access_token === undefined
        || cookies.access_token === '') && window.location.pathname != '/login') {
        window.location.href = "/login";
    }

    // validate auth token with server

    return (
        <CookiesProvider>
            <ScreenRouter />
        </CookiesProvider>
    );
}

export default App;
