import moment from 'moment';

export default class Helper {

    static httpErrorhandler = (error: any) => {
        console.log(error);
        if (error.response.status == 401) {
            console.log("User is unauthorized");
            window.location.href = "/login";
        }
    }

    static getSellObject = () => {
        return {
            sellId: 0,
            userId: 0,
            dateCreated: new Date(),
            dateForSell: new Date(),
            sellerName: '',
            caret: '',
            cent: '',
            viaBroker: '',
            size: '',
            price: '',
            commissionPercent: '0.50',
            dollarPrice: '',
            netLess: '0',
            buyerName: '',
            days: '7',
            commission: 0,
            sellValue: 0,
            paymentDueDate: moment().add(7, 'days').toISOString(),
            isReceived: false,
            isDebitNoteReceived: false,
            isActive: true,
            commissionType: 2
        };
    }
}