import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AddDalali from '../AddDalali/AddDalali';
import Dashboard from '../Dashboard/Dashboard';
import Login from '../Login/Login';

const ScreenRouter = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/addDalali" element={<AddDalali />} />
            </Routes>
        </BrowserRouter >
    );
}
export default ScreenRouter